import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const {
        data: {
          target: {
            file: { url },
          },
        },
      } = node
      return (
        <a href={url} target="_blank" rel="noreferrer">
          {children}
        </a>
      )
    },
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const SafetyResources = () => {
  const {
    contentfulEmployerPage: { resources },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          resources {
            raw
            references {
              ... on ContentfulAsset {
                __typename
                contentful_id
                description
                file {
                  url
                }
              }
            }
          }
        }
      }
    `
  )

  return (
    <section className={styles.safetyResources}>
      <div className={styles.container}>
        {renderRichText(resources, renderOptions)}
      </div>
    </section>
  )
}

export default SafetyResources
