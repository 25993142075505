import React from "react"
import * as styles from "./styles.module.less"
import FaqItem from "./components/FaqItem"

const Faq = ({ faqData }) => {
  return (
    <section className={styles.faq}>
      <div className={styles.textContainer}>
        <h1>{faqData.title}</h1>
      </div>
      {faqData &&
        faqData.list.map(({ id, label, question, answer }, index) => (
          <FaqItem
            label={label}
            question={question}
            answer={answer}
            key={id || index}
          />
        ))}
    </section>
  )
}

export default Faq
