import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

import Hero from "../Hero"
import BetterOutcomes from "./components/BetterOutcomes"
import Benefits from "./components/Benefits"
import LocationsMap, { LocationsMapHeader } from "../LocationsMap"
import EffectiveCare from "./components/EffectiveCare"
// import Portal from "./components/Portal"
import CallRep from "./components/CallRep"
import Faq from "../Faq"
import SafetyResources from "./components/SafetyResources"
import Disclaimer from "./components/Disclaimer"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const EmployersPage = () => {
  const {
    contentfulEmployerPage: {
      hero: {
        headline,
        heroImage: {
          description: heroAlt,
          file: { url: heroSrc },
        },
      },
      locations,
      faqIntro,
      faq,
      preFooter,
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          hero {
            headline
            eyebrow
            heroImage {
              file {
                url
              }
              description
            }
          }
          locations {
            raw
          }
          faqIntro
          faq {
            question {
              question
            }
            answer {
              raw
            }
          }
          preFooter {
            raw
          }
        }
      }
    `
  )

  const faqs = {
    title: faqIntro,
    list: faq.map(({ id, question: { question }, answer }) => {
      return {
        id,
        label: "Q:",
        question,
        answer: renderRichText(answer, renderOptions),
      }
    }),
  }

  return (
    <>
      <Hero headline={headline} image={heroSrc} alt={heroAlt} />
      <BetterOutcomes />
      <Benefits />
      <EffectiveCare />
      <section className={styles.locations}>
        <LocationsMapHeader />
        <LocationsMap />
        <div className={styles.copy}>
          <div className={styles.links}>
            <Link to="/Portland">Portland</Link>
            <Link to="/Beaverton">Beaverton</Link>
            <Link to="/Vancouver">Vancouver</Link>
            <Link to="/Salem">Salem</Link>
          </div>
          <div>{renderRichText(locations, renderOptions)}</div>
          <a className={styles.cta} href="/contact">
            Contact us
          </a>
        </div>
      </section>
      {/* <Portal /> */}
      <CallRep />
      <Faq faqData={faqs} />
      <SafetyResources />
      <Disclaimer>{renderRichText(preFooter, renderOptions)}</Disclaimer>
    </>
  )
}

export default EmployersPage
