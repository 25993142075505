import React from "react"
import * as styles from "./styles.module.less"

const FaqItem = ({ label, question, answer }) => {
  return (
    <details>
      <summary>
        <div className={styles.label}>{label}</div>
        <div className={styles.question}>{question}</div>
      </summary>
      {answer}
    </details>
  )
}

export default FaqItem
