import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const EffectiveCare = () => {
  const {
    contentfulEmployerPage: {
      costsIntro: {
        title,
        body,
        image: {
          description: alt,
          file: {
            url: src,
            details: { image: imageProps },
          },
        },
      },
      costs,
      costsFooter,
      costsCaseStudy: {
        file: { url: caseStudyDownload },
      },
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          costsIntro {
            title
            body {
              raw
            }
            image {
              description
              file {
                details {
                  image {
                    height
                    width
                  }
                }
                url
              }
            }
          }
          costs {
            id
            title
            body {
              raw
            }
            image {
              file {
                url
              }
              description
            }
          }
          costsFooter {
            raw
          }
          costsCaseStudy {
            file {
              url
            }
          }
        }
      }
    `
  )

  const renderCost = (cost) => {
    const {
      id,
      body,
      image: {
        description,
        file: { url },
      },
    } = cost
    return (
      <div key={id} className={styles.statsItem}>
        <img src={url} alt={description} />
        {renderRichText(body, renderOptions)}
      </div>
    )
  }

  return (
    <div className={styles.EffectiveCare}>
      <section>
        <div className={styles.twoColumn}>
          <img src={src} alt={alt} {...imageProps} />
          <div className={styles.copy}>
            <h2>{title}</h2>
            <div className={styles.subhead}>
              {renderRichText(body, renderOptions)}
            </div>
          </div>
        </div>
        <section className={styles.statsFlex}>{costs.map(renderCost)}</section>
        <div className={styles.copyContainer}>
          {renderRichText(costsFooter, renderOptions)}
          <a
            target="_blank"
            rel="noreferrer"
            href={caseStudyDownload}
            download
            className={styles.cta}
          >
            View the case study (PDF)
          </a>
        </div>
      </section>
    </div>
  )
}

export default EffectiveCare
