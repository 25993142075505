import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const Benefits = () => {
  const {
    contentfulEmployerPage: { benefitsIntro, benefits },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          benefitsIntro {
            raw
          }
          benefits {
            id
            icon {
              file {
                url
              }
              description
            }
            description {
              raw
            }
            title
          }
        }
      }
    `
  )

  const renderListItem = (benefit) => {
    const {
      id,
      description,
      icon: {
        description: iconAlt,
        file: { url },
      },
    } = benefit
    return (
      <div key={id} className={styles.benefitsFlex}>
        <img alt={iconAlt} src={url} />
        {renderRichText(description, renderOptions)}
      </div>
    )
  }

  return (
    <section className={styles.benefits}>
      <div className={styles.textContainer}>
        <div className={styles.body}>
          {renderRichText(benefitsIntro, renderOptions)}
        </div>
        {benefits.map(renderListItem)}
      </div>
    </section>
  )
}

export default Benefits
