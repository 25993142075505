import React from "react"
import * as styles from "./styles.module.less"

const Disclaimer = ({ children }) => {
  return (
    <section className={styles.disclaimer}>
      <div className={styles.body}>{children}</div>
    </section>
  )
}

export default Disclaimer
