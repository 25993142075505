import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const options = {
  renderText: (text) =>
    text.split("\n").map((t, i) =>
      i > 0 ? (
        <>
          <br />
          {t}
        </>
      ) : (
        t
      )
    ),
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const CallRep = () => {
  const {
    contentfulEmployerPage: { speakWithRep },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          speakWithRep {
            raw
          }
        }
      }
    `
  )
  return (
    <section className={styles.callRep}>
      <div className={styles.container}>
        {renderRichText(speakWithRep, options)}
        <a
          target="_blank"
          rel="noreferrer"
          className={styles.cta}
          href="https://business.kaiserpermanente.org/"
        >
          Learn how
        </a>
      </div>
    </section>
  )
}

export default CallRep
