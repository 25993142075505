import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { INLINES } from "@contentful/rich-text-types"

import * as styles from "./styles.module.less"

const renderOptions = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const {
        data: { uri },
      } = node
      let attr = {}
      if (uri.match(/^https?:\/\//)) {
        attr = {
          target: "_blank",
          rel: "noreferrer",
        }
      }
      return (
        <a href={uri} {...attr}>
          {children}
        </a>
      )
    },
  },
}

const BetterOutcomes = () => {
  const {
    contentfulEmployerPage: { overview },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          overview {
            raw
          }
        }
      }
    `
  )
  return (
    <section className={styles.betterOutcomes}>
      <div className={styles.container}>
        {renderRichText(overview, renderOptions)}
      </div>
    </section>
  )
}

export default BetterOutcomes
