import React from "react"

import { useStaticQuery, graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/seo"
import EmployersPage from "../components/EmployersPage"

const IndexPage = () => {
  const {
    contentfulEmployerPage: {
      title,
      seo: { title: seoTitle = "", description = "" },
    },
  } = useStaticQuery(
    graphql`
      {
        contentfulEmployerPage(slug: { eq: "employers" }) {
          title
          seo {
            title
            description
            language
          }
        }
      }
    `
  )
  return (
    <Layout>
      <Seo title={seoTitle || title} description={description} />
      <EmployersPage />
    </Layout>
  )
}

export default IndexPage
